import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next/router";
import { Constants } from "../../util/Constants";
import React, { useEffect, useState } from "react";
import { applyTruncationForScreen, findImageByKey, findValueByKey, getImage, getTarget, isItUndefined, isLinkAbsolute, isMobile, isTablet, truncateText } from "../../util/DataUtil";
import ReactPlayer from 'react-player';
import {getCookie, setCookie, deleteCookie} from 'cookies-next';
import {setMarketoCookieRedux} from "../../store/marketoCookieSlice";
import { useDispatch, useSelector } from "react-redux";
import { setVideoPlayingStateRedux } from "../../store/gatedVideoPlayingStateSlice";
// const ReactPlayer = dynamic(() => import('react-player'));

const TemplateVideo = ({children, width, hideTitle, playOnTitleClick}) => {
    const router = useRouter();
    const [data, setData] = useState(children);
    const [playingState, setPlayingState] = useState(false);
    const [playIcon, setPlayIcon] = useState('/images/play-button.svg');
    const [playIcon2, setPlayIcon2] = useState('/images/play-button.svg');
    const [playIcon3, setPlayIcon3] = useState('/images/play-button.svg');
    // const [mediaVideo, setMediaVideo] = useState(undefined);
    const [mediaVideo, setMediaVideo] = useState(isItUndefined(data?.field_video_url_link));
    const [mediaImage, setMediaImage] = useState(data?.field_main_image?.webimage ? data?.field_main_image?.webimage : '');
    const [isActive, setIsActive] = useState(true);
    const [mediaLength, setMediaLength] = useState(data?.field_carousel_items?.image_link_and_text?.length);
    const [videoHubCarouselClass, setVideoHubCaraouselClass] = useState('');
    const [finalHeightOfScrollBar ,setFinalHeightOfScrollBar] = useState('');
    const [isMediaChanged, setIsMediaChanged] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [titles, setTitles] = useState([])
    const marketoCookieState = useSelector(setMarketoCookieRedux);
    // const videoPlayingState = useSelector(setVideoPlayingStateRedux);
    // const autoplay = isItUndefined(children?.field_autoplay_gated_video);
    const dispatch = useDispatch();
    const [isItGated, setIsItGated] = useState(false);

    const truncate = () => {
        let titles = [];

        data?.field_carousel_items?.image_link_and_text.forEach((item, index) => {
            titles.push(
                applyTruncationForScreen(item.field_paragraph_title[0]?.value, 39, 57, 45)
            )
        })

        setTitles(titles)
    }

    useEffect(() => {
        truncate()
    }, [data])

    useEffect(() => {
        let verticalCarouselItemsHeight = document.getElementsByClassName('vertical-carousel')[0]?.offsetHeight;
        let extendBarHeight = document.getElementsByClassName('extend-bar')[0]?.offsetHeight;
        setVideoHubCaraouselClass(mediaLength > 0 && isMobile(width) ? "video-hub carousel" : 'video-hub');
        setFinalHeightOfScrollBar(verticalCarouselItemsHeight - extendBarHeight)
        // setMediaVideo(isItUndefined(data?.field_video_url_link));
        // setMediaImage(data?.field_main_image?.webimage ? data?.field_main_image?.webimage : '');

        if(typeof document !== "undefined"){
            let gated = document.querySelector('.gated_template');

            if(gated !== null){
                setIsItGated(true)
            }
        }
    }, [])

    const size = useWindowSize();
    let innerContainerWidth = 0;

    if(typeof document !== "undefined"){
        let innerContainer = document.querySelector('.inner-container .template-title');

        if(innerContainer !== null){
            innerContainerWidth = size?.width < 800 ? innerContainer?.clientWidth - 48 : innerContainer?.clientWidth - 96;
        }
    }
    
    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });

        useEffect(() => {
            if (typeof window !== 'undefined') {
                function handleResize() {
                    setWindowSize({
                        width: window.innerWidth,
                        height: window.innerHeight,
                    });
                }

                window.addEventListener("resize", handleResize);

                handleResize();

                return () => window.removeEventListener("resize", handleResize);
            }
        }, []);

        return windowSize;
    }

    useEffect(() => {
        setMediaVideo(data?.field_video_url_link[0]?.value);
        setMediaImage(data?.field_main_image?.webimage ? data?.field_main_image?.webimage : '');

        return () => {
            setMediaVideo(undefined);
            setMediaImage('')
        }
    },[isMediaChanged])

    useEffect(() => {
        const marketoIsSet = getCookie('marketoissetondlr');

        if(marketoIsSet && marketoIsSet !== undefined){
            setIsSubmitted(true);

            // if(autoplay === '1'){
            //     setPlayingState(true);
            // }
        }
    }, [marketoCookieState]);

    useEffect(() => {
        dispatch(setVideoPlayingStateRedux(playingState))
        // dispatch(setVideoPlayingStateRedux(true))
    }, [playingState])

    const changeItemFromCarousel = (item) => {
        if(!item.field_url_to_video[0]?.value && Object.keys(item.field_thumbnail_image).length == 0) return;
        if(item.field_url_to_video[0]?.value) {
            data.field_main_image = undefined;

            if(!data?.field_video_url_link[0]?.value){
                data.field_video_url_link = [{value:''}];
            }
            if(!data?.field_video_title[0]?.value){
                data.field_video_title =  [{value:''}];
            }

            if(!data?.field_watch_text[0]?.value){
                data.field_watch_text = [{value: ''}];
            }

            data.field_watch_text[0].value = item.field_label_text[0]?.value;
            data.field_video_title[0].value = item.field_paragraph_title[0]?.value
            data.field_video_url_link[0].value = item.field_url_to_video[0]?.value;
        }
        else if(item.field_type_of_item[0].value == 'Image' && !item.field_url_to_video[0]?.value && (item.field_thumbnail_image.webimage)){
            if(isItUndefined(data.field_video_url_link)){
                data.field_video_url_link[0].value = undefined;
            }
            data.field_main_image = item.field_thumbnail_image;
        }
        setData(data)
        setIsMediaChanged(!isMediaChanged);
    }

    const renderListNumbers = (items) => {
        if(!items.length){
            return;
        }

        return items.map((item, index) => {
            let numberOfMedia = index + 1;
            return <div onClick={() => { changeItemFromCarousel(item)}} className="circle" key={`circle-div-${index}`}>
                <span className="number">{numberOfMedia}</span>
            </div>
        })
    }

    const renderCaourselItems = (items) => {
        if(!items.length){
            return;
        }

        return items.map((item, index) => {
            return <div onClick={() => {changeItemFromCarousel(item)}} className="media" key={`media-key-${index}`}>
                <img alt="media" className="media-image" src={Constants.cloudinaryImage + item?.field_thumbnail_image?.webimage} />
                <div className="media-description">
                    <span className="media-view">{item.field_label_text[0]?.value}</span>
                    <span className="media-title">{titles[index]}</span>
                </div>
            </div>
        })
    }

    const renderWatch = (posts) => {
        let i = 1;
        return posts?.map((item, index) => {
            if(!item) return;

            i++;
            return(
                <a 
                    key={index} 
                    className={'div-template-media-link item'}
                    href={item['url-alias'].length !== 0 ? item['url-alias'] : '/'}
                    target={getTarget(item['url-alias'])}
                >
                <div>
                    <Image layout="fill" alt="play" onMouseLeave={() => index == 0 ? setPlayIcon2('/images/play-button.svg') : setPlayIcon3('/images/play-button.svg')} onMouseEnter={() => index == 0 ? setPlayIcon2('/images/play-button-green.svg') : setPlayIcon3('/images/play-button-green.svg')} onClick={() => {
                            index == 0 ? setPlayIcon2('/images/play-button.svg'):setPlayIcon3('/images/play-button.svg');}} 
                            className={'play'} src={index == 0 ? playIcon2 : playIcon3} />
                    <div className="content-image">
                        {getImage(findValueByKey('template_banner_block_type', item?.field_template_blocks)?.field_template_banner_bynder?.webimage, findValueByKey('template_banner_block_type', item?.field_template_blocks)?.field_web_small_image?.webimage) ? <Image objectFit="center" layout="fill" alt="video-and-content" src={getImage(findValueByKey('template_banner_block_type', item?.field_template_blocks)?.field_template_banner_bynder?.webimage, findValueByKey('template_banner_block_type', item?.field_template_blocks)?.field_web_small_image?.webimage)} />: <img alt="placeholder" src={'/images/placeholder.png'}/>}
                        {/* <Image layout="fill" alt="image" src={findImageByKey('template_banner_block_type', 'field_web_small_image', item.field_template_blocks)} /> */}
                    </div>
                </div>
                <span className={'category'}>{findValueByKey('template_media_block_type', item?.field_template_blocks)?.field_watch_text?.length > 0 ? findValueByKey('template_media_block_type', item?.field_template_blocks)?.field_watch_text[0]?.value : ''}</span>
                <span className={'title'}>{truncateText(findValueByKey('template_title_block_type', item?.field_template_blocks)?.field_template_title ? findValueByKey('template_title_block_type', item?.field_template_blocks)?.field_template_title[0].value : '', 50, '...')}</span>
                {i == 2 && <hr className="divider" />}
            </a>
            )
        })
    }

    return (
        <div className={`template-video ${data?.field_multimedia_variant[0]?.value === 'videoContent' ? 'video-and-content': ''}`}>
                {!hideTitle && <span className="page-sub">{data?.field_title[0]?.value}</span>}
                {!hideTitle && data?.field_title[0]?.value && <hr />}
                {/* DXP-3108 - REMOVE FOR PRODUCTION */}
                {/* <div className={!isItGated ? "video-content-wrapper" : "video-content-wrapper gated-video-wrapper" }> */}
                <div className={"video-content-wrapper"}>
                {/* {
                    (!isSubmitted && isItGated) &&
                    <div className="gated-locked" style={{ backdropFilter: `blur(${!isSubmitted ? '5px' : '0'})` }}>
                        <img src={'/images/lock.png'}  alt={'lock'} />
                        {isItUndefined(children?.field_gated_video_overlay_text)}
                    </div>
                } */}
                {/* DXP-3108 - REMOVE FOR PRODUCTION */}
                {/* DXP-3108 - REMOVE FOR PRODUCTION - SWITCH NEXT TO LINES - OLD BLOCK */}
                { mediaVideo || mediaImage ? <div className="video-container">
                {/* { mediaVideo || mediaImage ? <div className={"video-container"} style={{width: ((isItGated && isSubmitted && playingState) && innerContainerWidth !== 0) ? innerContainerWidth : '100%', transition: 'width 0.5s linear'}}> */}
                    <div className="embed-container">
                        {mediaVideo ? <ReactPlayer
                            key={mediaVideo}
                            onPlay={() => {
                                setPlayingState(true)
                            }}
                            onPause={() => {
                                setPlayingState(false)
                            }}
                            playing={playingState}
                            controls={true}
                            url={mediaVideo} /> : data?.field_main_image?.webimage ?  <div style={{backgroundImage: `url(${data?.field_main_image?.webimage ? Constants.cloudinaryImage + data?.field_main_image?.webimage : ''})`}} className="media-image"></div> : <></> }
                    </div>
                    {!playingState && mediaVideo && <div className={`${videoHubCarouselClass} ${(isMobile(width) || isTablet(width)) ? 'no-video-hub' : ''}`}>
                        <img alt="play" onMouseLeave={() => setPlayIcon('/images/play-button.svg')} onMouseEnter={() => setPlayIcon('/images/play-button-green.svg')} onClick={() => {
                            setPlayingState(true);
                            setPlayIcon('/images/play-button.svg');
                            }} className="video-play-button" src={playIcon} />
                        <div className="video-group-hub">
                            <label className="video-watch-text">{!playingState && data?.field_watch_text[0]?.value}</label>
                            <label onClick={() => {
                                if(playOnTitleClick){
                                    setPlayingState(true);
                                }
                                }} className="video-title">{!playingState && data?.field_video_title[0]?.value}</label>
                        </div>
                    </div>}
                    {mediaLength > 0 && children.field_multimedia_variant[0]?.value === 'videoAndImage' && !playingState && <div className={isActive ? 'vertical-carousel active' :'vertical-carousel'}>
                        <div className='extend-bar'>
                            <img alt="union" onClick={() => {
                                if(!isActive){
                                    setIsActive('active');
                                    return;
                                }
                                setIsActive(false)
                                }} className="extend-icon" src="/images/union.svg" />
                            <div className="vertical-line" />
                            <span className="video-list-length">{mediaLength} Videos</span>
                        </div>
                        <div style={{height: width >= 1024 ? `${finalHeightOfScrollBar}px` : 'auto'}} className="video-list">
                                {!isActive ? renderListNumbers(data.field_carousel_items.image_link_and_text) : renderCaourselItems(data.field_carousel_items.image_link_and_text)}
                        </div>
                    </div>}
                </div> : <></>}
                {/* DXP-3108 - REMOVE FOR PRODUCTION - OLD BLOCK */}

                {/* DXP-3108 - REMOVE FOR PRODUCTION - NEW BLOCK */}
                {/* {mediaVideo || mediaImage ? (
                isItGated ? (
                    <div
                    className="video-container"
                    style={{
                        width: isItGated && isSubmitted && playingState && innerContainerWidth !== 0 ? innerContainerWidth : '100%',
                        transition: 'width 0.5s linear',
                    }}
                    >
                    <div className="embed-container">
                        {mediaVideo ? (
                        <ReactPlayer
                            key={mediaVideo}
                            onPlay={() => setPlayingState(true)}
                            onPause={() => setPlayingState(false)}
                            playing={playingState}
                            controls={true}
                            url={mediaVideo}
                        />
                        ) : (
                        data?.field_main_image?.webimage && (
                            <div
                            style={{ backgroundImage: `url(${data.field_main_image.webimage ? Constants.cloudinaryImage + data.field_main_image.webimage : ''})` }}
                            className="media-image"
                            ></div>
                        )
                        )}
                    </div>
                    {!playingState && mediaVideo && (
                        <div className={`${videoHubCarouselClass} ${(isMobile(width) || isTablet(width)) ? 'no-video-hub' : ''}`}>
                        <img
                            alt="play"
                            onMouseLeave={() => setPlayIcon('/images/play-button.svg')}
                            onMouseEnter={() => setPlayIcon('/images/play-button-green.svg')}
                            onClick={() => {
                            setPlayingState(true);
                            setPlayIcon('/images/play-button.svg');
                            }}
                            className="video-play-button"
                            src={playIcon}
                        />
                        <div className="video-group-hub">
                            <label className="video-watch-text">{!playingState && data?.field_watch_text[0]?.value}</label>
                            <label
                            onClick={() => {
                                if (playOnTitleClick) {
                                setPlayingState(true);
                                }
                            }}
                            className="video-title"
                            >
                            {!playingState && data?.field_video_title[0]?.value}
                            </label>
                        </div>
                        </div>
                    )}
                    {mediaLength > 0 && children.field_multimedia_variant[0]?.value === 'videoAndImage' && !playingState && (
                        <div className={isActive ? 'vertical-carousel active' : 'vertical-carousel'}>
                        <div className="extend-bar">
                            <img
                            alt="union"
                            onClick={() => {
                                setIsActive(isActive ? false : 'active');
                            }}
                            className="extend-icon"
                            src="/images/union.svg"
                            />
                            <div className="vertical-line" />
                            <span className="video-list-length">{mediaLength} Videos</span>
                        </div>
                        <div style={{ height: width >= 1024 ? `${finalHeightOfScrollBar}px` : 'auto' }} className="video-list">
                            {!isActive ? renderListNumbers(data.field_carousel_items.image_link_and_text) : renderCaourselItems(data.field_carousel_items.image_link_and_text)}
                        </div>
                        </div>
                    )}
                    </div>
                ) : (
                    <div className="video-container">
                    <div className="embed-container">
                        {mediaVideo ? (
                        <ReactPlayer
                            key={mediaVideo}
                            onPlay={() => setPlayingState(true)}
                            onPause={() => setPlayingState(false)}
                            playing={playingState}
                            controls={true}
                            url={mediaVideo}
                        />
                        ) : (
                        data?.field_main_image?.webimage && (
                            <div
                            style={{ backgroundImage: `url(${data.field_main_image.webimage ? Constants.cloudinaryImage + data.field_main_image.webimage : ''})` }}
                            className="media-image"
                            ></div>
                        )
                        )}
                    </div>
                    {!playingState && mediaVideo && (
                        <div className={`${videoHubCarouselClass} ${(isMobile(width) || isTablet(width)) ? 'no-video-hub' : ''}`}>
                        <img
                            alt="play"
                            onMouseLeave={() => setPlayIcon('/images/play-button.svg')}
                            onMouseEnter={() => setPlayIcon('/images/play-button-green.svg')}
                            onClick={() => {
                            setPlayingState(true);
                            setPlayIcon('/images/play-button.svg');
                            }}
                            className="video-play-button"
                            src={playIcon}
                        />
                        <div className="video-group-hub">
                            <label className="video-watch-text">{!playingState && data?.field_watch_text[0]?.value}</label>
                            <label
                            onClick={() => {
                                if (playOnTitleClick) {
                                setPlayingState(true);
                                }
                            }}
                            className="video-title"
                            >
                            {!playingState && data?.field_video_title[0]?.value}
                            </label>
                        </div>
                        </div>
                    )}
                    {mediaLength > 0 && children.field_multimedia_variant[0]?.value === 'videoAndImage' && !playingState && (
                        <div className={isActive ? 'vertical-carousel active' : 'vertical-carousel'}>
                        <div className="extend-bar">
                            <img
                            alt="union"
                            onClick={() => {
                                setIsActive(isActive ? false : 'active');
                            }}
                            className="extend-icon"
                            src="/images/union.svg"
                            />
                            <div className="vertical-line" />
                            <span className="video-list-length">{mediaLength} Videos</span>
                        </div>
                        <div style={{ height: width >= 1024 ? `${finalHeightOfScrollBar}px` : 'auto' }} className="video-list">
                            {!isActive ? renderListNumbers(data.field_carousel_items.image_link_and_text) : renderCaourselItems(data.field_carousel_items.image_link_and_text)}
                        </div>
                        </div>
                    )}
                    </div>
                )
                ) : (
                <></>
                )} */}
                {/* DXP-3108 - REMOVE FOR PRODUCTION - NEW BLOCK */}

                {data?.field_multimedia_variant[0]?.value === 'videoContent' && <div className={'watch'}>
                    {renderWatch(data.field_watch_listen_posts)}
                </div>}
                </div>
        </div>
    )
}

export default TemplateVideo;
